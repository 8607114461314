<template>
    <v-card>
      <v-card-title class="text-h4">Дополнительная информация</v-card-title>
      <v-divider></v-divider>
      <v-list dense>
        <v-list-item>
          <v-list-item-content>Дата регистрации в ИСУНГ:</v-list-item-content>
          <v-list-item-content class="align-end">
            {{ contact.registerdate | date }}
          </v-list-item-content>
        </v-list-item>
  
        <v-list-item>
          <v-list-item-content>Ответственный исполнитель:</v-list-item-content>
          <v-list-item-content class="align-end">
            {{ contact.fullname }}
          </v-list-item-content>
        </v-list-item>
  
        <v-list-item>
          <v-list-item-content>Контакты:</v-list-item-content>
          <v-list-item-content class="align-end">
            {{ contact.phone }}
          </v-list-item-content>
        </v-list-item>
  
        <v-list-item>
          <v-list-item-content>E-mail:</v-list-item-content>
          <v-list-item-content class="align-end">
            {{ contact.email }}
          </v-list-item-content>
        </v-list-item>
  
        <v-list-item>
          <v-list-item-content>Статус:</v-list-item-content>
          <v-list-item-content class="align-end">
            {{ contact.status }}
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </template>
  <script>
  import { STATUS } from "@/util/globals";
  import { getContactByOrganizationId } from "@/api/reference.api.js";
  export default {
    name: "ItemContact",
    props: {
      registerDate: {
        type: String,
        default: "",
      },
    },
    data() {
      return {
        contacts: [],
        contact: {},
        defaultContact: {
          registerdate: "-",
          fullname: "-",
          phone: "-",
          email: "-",
          status: "-",
        },
      };
    },
    methods: {
      async initialize() {
        const organizationId = localStorage.getItem('organizationId');
        this.contacts = await getContactByOrganizationId(organizationId);
      },
    },
    async mounted() {
      await this.initialize();
      // const candidate = this.contacts.find(
      //   (item) => item.status.Code == STATUS.Active
      // );
      const candidate = this.contacts[0];
      if (candidate) {
        this.contact = {
          registerdate: this.registerDate,
          fullname: candidate.firstName + " " + candidate.lastName,
          phone: candidate.phone,
          email: candidate.email,
          status: candidate.status.nameRu,
        };
      } else {
        this.contact = this.defaultContact;
      }
    },
  };
  </script>